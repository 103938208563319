import RedoIcon from "@assets/icons/RedoIcon"
import clsx from "clsx"
import { ReactNode } from "react"
import css from "./ResendEmailVerificationEmail.module.css"
import cssSpinner from "@components/shared/LoadingIcon/LoadingIcon.module.css";


interface ResendEmailVerificationEmailInterface {
	resendEmail: () => void
	busy: boolean
	children: ReactNode
}

const ResendEmailVerificationEmail = ({ busy=false, resendEmail = () => { }, children }: ResendEmailVerificationEmailInterface) => {
	return (
		<div className={css.resendEmail} onClick={resendEmail}>
			<RedoIcon className={clsx({ [cssSpinner.spiningAntiClockWise]: busy })} />
			{children}
		</div>
	)
}
export default ResendEmailVerificationEmail