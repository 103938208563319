import React from "react";

interface CloseIconInterface {
  svgFill?: string;
  strokeColor?: string;
  width?: number;
  height?: number;
}
const CloseIcon = ({ width = 22, height = 23, svgFill = "none", strokeColor = "#0F0518" }: CloseIconInterface) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 23" fill={svgFill} xmlns="http://www.w3.org/2000/svg">
      <path d="M7 7.5L15 15.5M15 7.5L7 15.5" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
export default CloseIcon;
