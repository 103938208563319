import React from "react";
import cssDangerBtn from "./DangerBtn.module.css";
import RegularButton from "../RegularButton";

interface DangerButtonInterface {
  text: string;
  link?: string;
  onClick?: () => void;
}
const DangerButton = ({ text, link, onClick = () => {} }: DangerButtonInterface) => {
  return <RegularButton text={text} variant="primary" theme={cssDangerBtn} onClick={onClick} link={link} />;
};
export default DangerButton;
