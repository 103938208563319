import React, { ReactNode } from "react";

import ChevronLeftIcon from "@assets/icons/ChevronLeftIcon";

import css from "./BackButton.module.css";

interface BackButtonInterface {
  onClick?: () => void;
  text?: ReactNode;
}
const BackButton = ({ text = "Back", onClick }: BackButtonInterface) => {
  return (
    <div className={css.backBtnWrap} onClick={onClick}>
      <ChevronLeftIcon className={css.backBtnIcon} />
      {text}
    </div>
  );
};
export default BackButton;
