/**
 * @generated SignedSource<<1d947c8f63fc8645ceee7c1d38dc12b9>>
 * @relayHash 552a97faa046666ede153851b41ef414
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 552a97faa046666ede153851b41ef414

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChangeUserUsernameInput = {
  clientMutationId?: string | null | undefined;
  username: any;
};
export type Account_ChangeUsername_SettingsAccountPageMutation$variables = {
  input: ChangeUserUsernameInput;
};
export type Account_ChangeUsername_SettingsAccountPageMutation$data = {
  readonly changeUserUsername: {
    readonly user: {
      readonly id: string;
      readonly username: string;
    } | null | undefined;
  } | null | undefined;
};
export type Account_ChangeUsername_SettingsAccountPageMutation = {
  response: Account_ChangeUsername_SettingsAccountPageMutation$data;
  variables: Account_ChangeUsername_SettingsAccountPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangeUserUsernamePayload",
    "kind": "LinkedField",
    "name": "changeUserUsername",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Account_ChangeUsername_SettingsAccountPageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Account_ChangeUsername_SettingsAccountPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "552a97faa046666ede153851b41ef414",
    "metadata": {},
    "name": "Account_ChangeUsername_SettingsAccountPageMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "c22943ac5d881e6cd89db4a3919f26a0";

export default node;
