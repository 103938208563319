import clsx from "clsx";
import Cookies from "js-cookie";
import React, { ReactNode, useCallback, useState } from "react";
import { graphql, useFragment, useMutation } from "react-relay";
import { useLocalStorage } from "react-use";

import Divider from "@components/Divider";
import PackageRow from "@components/package/PackageRow";
import Adorment from "@components/shared/Adorment";
import ConfirmButton from "@components/shared/Buttons/ConfirmButton";
import DangerButton from "@components/shared/Buttons/DangerButton";
import RegularButton from "@components/shared/Buttons/RegularButton";
import ResendButton from "@components/shared/Buttons/ResendButton";
import Card from "@components/shared/Cards/Card";
import DefaultInputBox from "@components/shared/InputBox/DefaultInputBox";
import LoadingIcon from "@components/shared/LoadingIcon";
import Modal, { ModalFooter } from "@components/shared/Modal";
import { ToasterAlertDefault, ToasterAlertError } from "@components/shared/ToasterAlert";
import { Account_ChangeUsername_SettingsAccountPageMutation } from "@generated/Account_ChangeUsername_SettingsAccountPageMutation.graphql";
import { Account_ReqEmailValidation_SettingsAccountPageMutation } from "@generated/Account_ReqEmailValidation_SettingsAccountPageMutation.graphql";
import {
  Account_SettingsAccount$data,
  Account_SettingsAccount$key,
} from "@generated/Account_SettingsAccount.graphql";
import {
  Account_SettingsAccountPageMutation,
  Account_SettingsAccountPageMutation$variables,
} from "@generated/Account_SettingsAccountPageMutation.graphql";
import {
  Account_UpdateEmail_SettingsAccountPageMutation,
  Account_UpdateEmail_SettingsAccountPageMutation$variables,
} from "@generated/Account_UpdateEmail_SettingsAccountPageMutation.graphql";
import { Account_UpdatePassword_SettingsAccountPageMutation } from "@generated/Account_UpdatePassword_SettingsAccountPageMutation.graphql";

import css from "./Account.module.css";
import emailInput from "./EmailInput.module.css";
import ProfilePicture from "./ProfilePicture";
import ResendEmailVerificationEmail from "./ResendEmailVerification";

interface userDataInterface extends Account_SettingsAccount$data {
  oldpass?: string;
  newpass?: string;
  repass?: string;
}
type handleUserDataChangeInterface = (field: string, value: string) => void;

export interface SettingsAccountPageInterface {
  user: Account_SettingsAccount$key;
}

const SettingsAccountPage = (props: SettingsAccountPageInterface) => {
  const [updateUserDetails, _isInFlight] = useMutation<Account_SettingsAccountPageMutation>(graphql`
    mutation Account_SettingsAccountPageMutation($input: UpdateUserInfoInput!) {
      updateUserInfo(input: $input) {
        clientMutationId
        user {
          id
          username
          fullName
          bio
          avatar
          email
          isEmailValidated
          githubUrl
          twitterUrl
          websiteUrl
          location
        }
      }
    }
  `);

  const [updateEmail, _] = useMutation<Account_UpdateEmail_SettingsAccountPageMutation>(graphql`
    mutation Account_UpdateEmail_SettingsAccountPageMutation($input: ChangeUserEmailInput!) {
      changeUserEmail(input: $input) {
        user {
          id
          username
          email
          isEmailValidated
        }
      }
    }
  `);

  const [updatePassword, _iswrkg] = useMutation<Account_UpdatePassword_SettingsAccountPageMutation>(graphql`
    mutation Account_UpdatePassword_SettingsAccountPageMutation($input: ChangeUserPasswordInput!) {
      changeUserPassword(input: $input) {
        token
      }
    }
  `);

  const [requestEmailValidation, requestingEmailValidation] =
    useMutation<Account_ReqEmailValidation_SettingsAccountPageMutation>(graphql`
      mutation Account_ReqEmailValidation_SettingsAccountPageMutation($input: RequestValidationEmailInput!) {
        requestValidationEmail(input: $input) {
          success
          user {
            id
            username
            email
            isEmailValidated
          }
        }
      }
    `);

  const [changeUsername, changingUsername] = useMutation<Account_ChangeUsername_SettingsAccountPageMutation>(graphql`
    mutation Account_ChangeUsername_SettingsAccountPageMutation($input: ChangeUserUsernameInput!) {
      changeUserUsername(input: $input) {
        user {
          id
          username
        }
      }
    }
  `);

  const user = useFragment(
    graphql`
      fragment Account_SettingsAccount on User {
        id
        username
        fullName
        bio
        avatar
        email
        isEmailValidated
        githubUrl
        twitterUrl
        websiteUrl
        location
        packages {
          edges {
            node {
              id
              name
              namespace
              packageName
              icon
            }
          }
        }
      }
    `,
    props.user
  );

  let [storedAccounts, setStoredAccounts] = useLocalStorage("userAccounts", {});

  const [userData, setUserData] = useState<userDataInterface>(user);
  const [detailsUpdating, setDetailsUpdating] = useState(false);
  const [emailUpdating, setEmailUpdating] = useState(false);
  const [avatarUpdating, setAvatarUpdating] = useState(false);
  const [passUpdating, setPassUpdating] = useState(false);
  const [socialsUpdating, setSocialsUpdating] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openChangeUsernameModal, setOpenChangeUsernameModal] = useState<"" | "packages" | "changeusername" | "review">(
    ""
  );
  const [confirmUsername, setConfirmUsername] = useState("");
  const [newUsername, setNewUsername] = useState("");

  const handleUserDataChange: handleUserDataChangeInterface = (field, value) => {
    setUserData({ ...userData, [field]: value });
  };

  const handleSaveDetails = useCallback(() => {
    let variables: Account_SettingsAccountPageMutation$variables = {
      input: {
        fullName: userData.fullName,
        bio: userData.bio,
      },
    };
    setDetailsUpdating(true);
    updateUserDetails({
      variables,
      onCompleted: (_, errors) => {
        setDetailsUpdating(false);
        if (errors && errors.length) {
          ToasterAlertError({ message: errors[0].message });
        } else {
          ToasterAlertDefault({ message: `Profile details updated` });
        }
      },
      onError: () => {
        setDetailsUpdating(false);
        ToasterAlertError({ message: "An error while updating profile details, please try again later" });
      },
    });
  }, [updateUserDetails, userData]);

  const uploadAvatar = useCallback(
    (newAvatar: File) => {
      if (!newAvatar) return;
      let variables: Account_SettingsAccountPageMutation$variables = {
        input: {
          avatar: "avatarFile",
        },
      };
      let uploadables = { avatarFile: newAvatar };

      setAvatarUpdating(true);
      updateUserDetails({
        variables,
        uploadables,
        onCompleted: (response, errors) => {
          setAvatarUpdating(false);

          if (errors && errors.length) {
            ToasterAlertError({ message: errors[0].message });
          } else {
            setUserData({ ...userData, avatar: response.updateUserInfo?.user?.avatar || "" });
            ToasterAlertDefault({ message: `Your profile picture was updated` });
          }
        },
        onError: () => {
          setAvatarUpdating(false);
          ToasterAlertError({ message: "An error while updating your profile picture, please try again later" });
        },
      });
    },
    [updateUserDetails]
  );

  const handleUpdateEmail = useCallback(() => {
    let variables: Account_UpdateEmail_SettingsAccountPageMutation$variables = {
      input: { newEmail: userData.email },
    };
    setEmailUpdating(true);
    updateEmail({
      variables,
      onCompleted: (response, errors) => {
        setEmailUpdating(false);
        if (errors && errors.length) {
          ToasterAlertError({ message: errors[0].message });
        } else {
          setUserData({ ...userData, isEmailValidated: response.changeUserEmail?.user.isEmailValidated || false });

          ToasterAlertDefault({ message: `Email changed to ${userData.email}` });
          setOpenEmailModal(true);
        }
      },
      onError: () => {
        setEmailUpdating(false);
        ToasterAlertError({ message: `An error while updating your email, please try again later` });
      },
    });
  }, [updateEmail, userData]);

  const handleUpdatePassword = useCallback(() => {
    if (userData.newpass?.trim() == "" || userData.oldpass?.trim() == "") return;
    const variables = {
      input: { password: userData.newpass || "", oldPassword: userData.oldpass || "" },
    };
    setPassUpdating(true);
    updatePassword({
      variables,
      onCompleted: (_response, errors) => {
        setPassUpdating(false);

        if (errors && errors.length) {
          ToasterAlertError({ message: errors[0].message });
        } else {
          const newToken = _response?.changeUserPassword?.token;
          Cookies.set("auth_token", newToken as string, { expires: 365 });
          setUserData({ ...userData, oldpass: "", newpass: "", repass: "" });
          ToasterAlertDefault({ message: `Your password was updated` });
        }
      },
      onError: (error) => {
        setPassUpdating(false);

        console.error(error);
        ToasterAlertError({ message: `An error while updating your password, please try again later` });
      },
    });
  }, [updatePassword, userData]);

  const handleUpdateSocials = useCallback(() => {
    let variables: Account_SettingsAccountPageMutation$variables = {
      input: {
        github: userData.githubUrl,
        twitter: userData.twitterUrl,
        websiteUrl: userData.websiteUrl,
        location: userData.location,
      },
    };
    setSocialsUpdating(true);
    updateUserDetails({
      variables,
      onCompleted: (_response, errors) => {
        setSocialsUpdating(false);

        if (errors && errors.length) {
          ToasterAlertError({ message: errors[0].message });
        } else {
          ToasterAlertDefault({ message: `Your social urls was updated` });
        }
      },
      onError: () => {
        setSocialsUpdating(false);
        ToasterAlertError({ message: `An error while updating your social urls, please try again later` });
      },
    });
  }, [updateUserDetails, userData]);

  const resendEmail = useCallback(() => {
    if (requestingEmailValidation) return;
    requestEmailValidation({
      variables: { input: {} },
      onCompleted: (_response, errors) => {
        if (errors && errors.length) {
          ToasterAlertError({ message: errors[0].message });
        } else {
          ToasterAlertDefault({ message: "Your request to validate your email was sent " });
        }
      },
      onError: (error) => {
        console.error(error);
        ToasterAlertError({
          message: `An error while requesting the confirmation of your email, please try again later`,
        });
      },
    });
  }, [requestEmailValidation, user]);

  const handleChangeUsername = useCallback(() => {
    const variables = {
      input: { username: newUsername },
    };
    changeUsername({
      variables,
      onCompleted: (_response, errors) => {
        if (errors && errors.length) {
          ToasterAlertError({ message: errors[0].message });
        } else {
          const _storedAccounts: { [index: string]: any } = { ...storedAccounts };
          const strAccount = _storedAccounts[userData.username];
          delete _storedAccounts[userData.username];
          _storedAccounts[newUsername] = strAccount;

          setStoredAccounts(_storedAccounts);
          Cookies.set("username", newUsername, { expires: 365 });
          setUserData({ ...userData, username: newUsername });
          ToasterAlertDefault({ message: `Your username was changed to ${newUsername}` });
          setNewUsername("");
        }
        setOpenChangeUsernameModal("");
      },
      onError: (error) => {
        console.error(error);
        setOpenChangeUsernameModal("");
        ToasterAlertError({ message: `An error while updating your username, please try again later` });
      },
    });
  }, [changeUsername, newUsername, setStoredAccounts, setOpenChangeUsernameModal]);

  return (
    <>
      <Card className={css.card}>
        <div className={css.profileDetails}>
          <CardHeader title="Your profile details" subTitle="Add your name and some basic info about yourself." />
          <CardForm>
            <DefaultInputBox
              initValue={userData.fullName}
              onChange={(e: React.ChangeEvent<HTMLInputElement> | any) =>
                handleUserDataChange("fullName", e.target.value)
              }
              placeholder="Full name"
              label="Full name"
              showIcon={false}
              type="text"
              className={css.inputBox}
            />
            <DefaultInputBox
              initValue={userData.bio || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement> | any) => handleUserDataChange("bio", e.target.value)}
              multi={true}
              rows={4}
              placeholder="Your bio"
              label="Your bio"
              showIcon={false}
              className={css.inputBox}
            />
            <CardActionBtn
              working={detailsUpdating}
              disabled={(userData.fullName.trim() || "") == "" || detailsUpdating}
              text="Update details"
              onClick={handleSaveDetails}
            />
            <Divider className={css.divider} />
            <ProfilePicture
              uploading={avatarUpdating}
              avatar={userData.avatar}
              title="Profile picture"
              subTitle="Upload a nice picture of yourself. "
              onAvatarChange={uploadAvatar}
            />
          </CardForm>
        </div>
      </Card>
      <Card className={css.card}>
        <div className={css.profileDetails}>
          <CardHeader title="Email" subTitle="Set your email for notifications. It won't be shared on your profile." />
          <CardForm>
            <div className={css.emailSection}>
              <div className={css.inputBoxAdorment}>
                <DefaultInputBox
                  initValue={userData.email || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement> | any) =>
                    handleUserDataChange("email", e.target.value)
                  }
                  placeholder="Email"
                  label="Email"
                  showIcon={false}
                  type="email"
                  theme={emailInput}
                  className={clsx(css.inputBox)}
                />
                <Adorment
                  variant={userData.isEmailValidated ? "verified" : "verifying"}
                  text={userData.isEmailValidated ? "Verified" : "Verifying"}
                />
              </div>
              {!userData.isEmailValidated && (
                <ResendEmailVerificationEmail resendEmail={resendEmail} busy={requestingEmailValidation} >Resend verification email</ResendEmailVerificationEmail>
              )}
              <Modal
                open={openEmailModal}
                header="Confirm your email"
                subHeader="Click the link inside to confirm this action."
                onClose={() => {
                  setOpenEmailModal(false);
                }}
                footer={
                  <ModalFooter
                    cancelBtn={<ResendButton onClick={resendEmail} spinning={requestingEmailValidation} />}
                    confirmBtn={
                      <ConfirmButton showIcon={false} text="Got it" onClick={() => setOpenEmailModal(false)} />
                    }
                  />
                }
              />
            </div>
            <CardActionBtn
              working={emailUpdating}
              disabled={(userData.email.trim() || "") == "" || user.email == userData.email.trim() || emailUpdating}
              text="Update email"
              onClick={handleUpdateEmail}
            />
          </CardForm>
        </div>
      </Card>
      <Card className={css.card}>
        <div className={css.profileDetails}>
          <CardHeader title="Password" subTitle="Pick a strong, memorable password of at least 8 characters. " />
          <CardForm>
            <DefaultInputBox
              autoComplete={"new-password"}
              onChange={(e: React.ChangeEvent<HTMLInputElement> | any) =>
                handleUserDataChange("oldpass", e.target.value)
              }
              placeholder="Old password"
              label="Old password"
              showIcon={false}
              type="password"
              className={css.inputBox}
              value={userData.oldpass}
            />
            <DefaultInputBox
              autoComplete={"new-password"}
              onChange={(e: React.ChangeEvent<HTMLInputElement> | any) =>
                handleUserDataChange("newpass", e.target.value)
              }
              placeholder="New password"
              label="New password"
              showIcon={false}
              type="password"
              className={css.inputBox}
              value={userData.newpass}
            />
            <DefaultInputBox
              autoComplete={"new-password"}
              onChange={(e: React.ChangeEvent<HTMLInputElement> | any) =>
                handleUserDataChange("repass", e.target.value)
              }
              placeholder="Repeat password"
              label="Repeat password"
              showIcon={false}
              type="password"
              className={css.inputBox}
              value={userData.repass}
            />
            <CardActionBtn
              working={passUpdating}
              disabled={
                (userData.oldpass?.trim() || "") == "" ||
                (userData.newpass?.trim() || "") == "" ||
                userData.oldpass == userData.newpass ||
                userData.repass != userData.newpass ||
                passUpdating
              }
              text="Update password"
              onClick={handleUpdatePassword}
            />
          </CardForm>
        </div>
      </Card>
      <Card className={css.card}>
        <div className={css.profileDetails}>
          <CardHeader title="Social" subTitle="Add your socials. This enables others to reach out to you." />
          <CardForm>
            <DefaultInputBox
              initValue={userData.githubUrl || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement> | any) =>
                handleUserDataChange("githubUrl", e.target.value)
              }
              placeholder="Github URL"
              label="Github URL"
              showIcon={false}
              type="text"
              className={css.inputBox}
            />
            <DefaultInputBox
              initValue={userData.twitterUrl || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement> | any) =>
                handleUserDataChange("twitterUrl", e.target.value)
              }
              placeholder="Twitter URL"
              label="Twitter URL"
              showIcon={false}
              type="text"
              className={css.inputBox}
            />
            <DefaultInputBox
              initValue={userData.websiteUrl || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement> | any) =>
                handleUserDataChange("websiteUrl", e.target.value)
              }
              placeholder="Website Url"
              label="Website Url"
              showIcon={false}
              type="text"
              className={css.inputBox}
            />
            <DefaultInputBox
              initValue={userData.location || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement> | any) =>
                handleUserDataChange("location", e.target.value)
              }
              placeholder="Location"
              label="Location"
              showIcon={false}
              type="text"
              className={css.inputBox}
            />
            <CardActionBtn working={socialsUpdating} text="Update socials" onClick={handleUpdateSocials} />
          </CardForm>
        </div>
      </Card>

      <Card className={clsx(css.card, css.dangerZone)}>
        <div className={css.dangerContent}>
          <CardHeader state="danger" title="Danger zone" className={css.dangerZoneHeader} />
          <Card className={css.dangerCard}>
            <div className={css.dangerCardContent}>
              <div className={css.dangerCol}>
                <CardHeader
                  title="Change username"
                  state="danger"
                  subTitle="Changing your username may have upstream implications for developers and CI/CD systems using your packages."
                />
                <div className={clsx(css.subTitle, css.subTitleDanger)}>
                  Your username: <span className={css.DangerZoneUsername}>{userData.username}</span>
                </div>
              </div>
              <DangerButton text="Change username" onClick={() => setOpenChangeUsernameModal("packages")} />
            </div>
            <Divider className={css.dividerDangerZone} />
            <div className={css.dangerCardContent}>
              <div className={css.dangerCol}>
                <CardHeader
                  title="Delete account"
                  state="danger"
                  subTitle="Accounts can't be automatically deleted yet, please contact Wasmer to request the deletion of your account."
                />
              </div>
              <DangerButton text="Contact wasmer" link="mailto:support@wasmer.io" />
            </div>

            <Modal
              className={css.changeUserModal}
              open={openChangeUsernameModal == "packages"}
              header="Changing your username affects your packages"
              subHeader={
                <>
                  The names, urls and install commands of your packages not belonging to namespaces will affected.
                  Please consider this potentially breaking change before continuing.
                  <div className={css.pkgsSectionLabel}>
                    <span className={css.pkgsCount}>({userData.packages.edges.length})</span> packages to update
                  </div>
                </>
              }
              body={
                <div className={css.pkgsSection}>
                  {userData.packages.edges.map((pkg, idx) => (
                    <PackageRow
                      key={idx}
                      iconUrl={pkg?.node?.icon}
                      namespace={userData.username}
                      pkgName={pkg?.node?.packageName}
                    />
                  ))}
                </div>
              }
              footer={
                <ModalFooter
                  confirmBtn={
                    <ConfirmButton
                      showIcon={false}
                      text="Continue anyway"
                      onClick={() => setOpenChangeUsernameModal("changeusername")}
                    />
                  }
                />
              }
              onClose={() => setOpenChangeUsernameModal("")}
            />

            <Modal
              className={css.changeUserModal}
              open={openChangeUsernameModal == "changeusername"}
              header="Choose a new username"
              subHeader={"Type in your new name and confirm it to continue."}
              body={
                <div className={css.usernameSection}>
                  <DefaultInputBox
                    onChange={(ev: React.ChangeEvent<HTMLInputElement> | any) => setNewUsername(ev.target.value)}
                    placeholder="New username"
                    label="New username"
                    showIcon={false}
                    fullWidth
                  />
                </div>
              }
              footer={
                <ModalFooter
                  cancelBtn={
                    <DefaultInputBox
                      onChange={(ev: React.ChangeEvent<HTMLInputElement> | any) => setConfirmUsername(ev.target.value)}
                      fullWidth
                      showIcon={false}
                      placeholder={`type ${userData.username}`}
                      label={`type ${userData.username}`}
                    />
                  }
                  confirmBtn={
                    <ConfirmButton
                      showIcon={false}
                      text="Continue"
                      state="danger"
                      disabled={confirmUsername != userData.username || newUsername.trim() == ""}
                      onClick={() => setOpenChangeUsernameModal("review")}
                    />
                  }
                />
              }
              onClose={() => setOpenChangeUsernameModal("")}
            />

            <Modal
              className={css.changeUserModal}
              open={openChangeUsernameModal == "review"}
              header="Changing your username affects your packages"
              subHeader={
                <>
                  The names, urls and install commands of the following packages will change.
                  <div className={css.pkgsSectionLabel}>
                    <span className={css.pkgsCount}>({userData.packages.edges.length})</span> packages to update
                  </div>
                </>
              }
              body={
                <div className={css.pkgsSection}>
                  {userData.packages.edges.map((pkg, idx) => (
                    <PackageRow
                      key={idx}
                      iconUrl={pkg?.node?.icon}
                      namespace={newUsername}
                      pkgName={pkg?.node?.packageName}
                    />
                  ))}
                </div>
              }
              footer={
                <ModalFooter
                  confirmBtn={
                    <>
                      {changingUsername && <LoadingIcon />}
                      <ConfirmButton
                        showIcon={false}
                        text="Change name"
                        state="danger"
                        onClick={handleChangeUsername}
                      />
                    </>
                  }
                />
              }
              onClose={() => setOpenChangeUsernameModal("")}
            />
          </Card>
        </div>
      </Card>
    </>
  );
};
export default SettingsAccountPage;

interface CardHeaderInterface {
  title: string;
  subTitle?: string;
  className?: string;
  state?: "danger" | "warning";
}
export const CardHeader = ({ title, subTitle, state, className }: CardHeaderInterface) => {
  return (
    <div className={clsx(css.CardHeaderWrapper, className)}>
      <div className={clsx(css.title)}>{title}</div>
      {subTitle && (
        <div
          className={clsx(css.subTitle, {
            [css.subTitleDanger]: state == "danger",
            [css.subTitleWarning]: state == "warning",
          })}
        >
          {subTitle}
        </div>
      )}
    </div>
  );
};

interface CardFormInterface {
  children: ReactNode;
}
export const CardForm = ({ children }: CardFormInterface) => {
  return <div className={css.CardForm}>{children}</div>;
};

interface CardActionBtnInterface {
  working?: boolean;
  text: string;
  disabled?: boolean;
  variant?: "primary" | "secondary" | "tertiary" | "plain";
  onClick: () => void;
}
export const CardActionBtn = ({ working, text, disabled, onClick, variant }: CardActionBtnInterface) => {
  return (
    <div className={css.CardActionBtnWrapper}>
      <div className={css.content}>
        <div className={css.btnWrap}>
          {working && <LoadingIcon />}
          <RegularButton
            variant={variant}
            disabled={disabled}
            text={text}
            onClick={onClick}
            className={css.CardActionBtn}
          />
        </div>
      </div>
    </div>
  );
};
