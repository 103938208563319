import React from "react";

interface ChevronLeftIconInterface {
  className?: string;
  fillColor?: string;
  strokeColor?: string;
}
const ChevronLeftIcon: React.FC<ChevronLeftIconInterface> = ({
  fillColor = "none",
  strokeColor = "#FBFBFC",
  className = "",
}: ChevronLeftIconInterface) => {
  return (
    <svg
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill={fillColor}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 1.5L5.5 4.5L2.5 7.5"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronLeftIcon;
