import type { Config } from "tailwindcss";
// const { colors: defaultColors } = require('tailwindcss/defaultTheme')

// const config: Config = {
//   content: [
//     "./pages/**/*.{js,ts,jsx,tsx,mdx}",
//     "./components/**/*.{js,ts,jsx,tsx,mdx}",
//     "./app/**/*.{js,ts,jsx,tsx,mdx}",
//   ],
//   theme: {
//     extend: {
//       backgroundImage: {
//         "gradient-radial": "radial-gradient(var(--tw-gradient-stops))",
//         "gradient-conic":
//           "conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))",
//       },
//     },
//   },
//   plugins: [],
// };
// export default config;

const dotSizes: any = {};
const plugin = require("tailwindcss/plugin");

for (let i = 2; i < 25; i++) {
  dotSizes[`dot-${i}`] = `calc(var(--spacing) * ${i - 1} + var(--dot-size))`;
}

const config: Config = {
  darkMode: ["class"],
  content: [
    "./src/**/*.{js,ts,jsx,tsx,mdx}",
    "./@/**/*.{js,ts,jsx,tsx,mdx}",
    "./src/app/**/*.{js,ts,jsx,tsx,mdx}",
    "./src/components/**/*.{js,ts,jsx,tsx,mdx}",
  ],
  safelist: [
    "w-dot-1",
    "w-dot-2",
    "w-dot-3",
    "w-dot-4",
    "w-dot-5",
    "w-dot-6",
    "w-dot-7",
    "w-dot-8",
    "w-dot-9",
    "w-dot-10",
    "w-dot-11",
    "w-dot-12",
    "h-dot-1",
    "h-dot-2",
    "h-dot-3",
    "h-dot-4",
    "h-dot-5",
    "h-dot-6",
    "h-dot-7",
    "h-dot-8",
    "h-dot-9",
    "h-dot-10",
    "h-dot-11",
    "h-dot-12",
    "py-0",
    "py-1",
    "py-2",
    "py-3",
    "py-4",
    "py-5",
    "py-6",
    "py-7",
    "py-8",
    "py-9",
    "py-10",
    "py-11",
    "py-12",
  ],
  theme: {
    extend: {
      fontFamily: {
        sans: ["Gilroy", "sans-serif"],
        gilroy: ["Gilroy", "sans-serif"],
        mono: ["Zeitung Mono Pro", "monospace"],
        zeitung: ["Zeitung Mono Pro", "monospace"],
      },
      colors: {
        transparent: "transparent",
        current: "currentColor",
        primary: {
          DEFAULT: "hsl(var(--primary))",
          foreground: "hsl(var(--primary-foreground))",
        },
        secondary: {
          DEFAULT: "hsl(var(--secondary))",
          foreground: "hsl(var(--secondary-foreground))",
        },
        neutral: "#231044",
        danger: "#EF476F",
        success: "#3bb54a",
        completed: {
          DEFAULT: "hsla(155, 98%, 39%, 1)",
          foreground: "hsla(155, 98%, 39%, 0.15)",
        },
        "background-base": "rgb(240,242,247)",
        "border-gray-box": "rgba(15, 5, 24, 0.15)",
        background: "hsl(var(--background))",
        warning: "rgba(250, 158, 51, 1)",
        "warning-50": "rgba(250, 158, 51, 0.5)",
        "warning-40": "rgba(250, 158, 51, 0.4)",
        "warning-30": "rgba(250, 158, 51, 0.3)",
        "warning-20": "rgba(250, 158, 51, 0.2)",
        "warning-10": "rgba(250, 158, 51, 0.1)",
        purple: "rgba(73, 70, 221)",
        "purple-90": "rgba(73, 70, 221, 0.9)",
        "purple-80": "rgba(73, 70, 221, 0.8)",
        "purple-70": "rgba(73, 70, 221, 0.7)",
        "purple-60": "rgba(73, 70, 221, 0.6)",
        "purple-50": "rgba(73, 70, 221, 0.5)",
        "purple-20": "rgba(73, 70, 221, 0.2)",
        darkpurple: "rgba(35, 16, 68)",
        "darkpurple-15": "rgba(35, 16, 68, 0.15)",
        "darkpurple-20": "rgba(35, 16, 68, 0.2)",
        "darkpurple-30": "rgba(35, 16, 68, 0.3)",
        "darkpurple-45": "rgba(35, 16, 68, 0.45)",
        "darkpurple-50": "rgba(35, 16, 68, 0.5)",
        "darkpurple-80": "rgba(35, 16, 68, 0.8)",
        "darkpurple-60": "rgba(35, 16, 68, 0.6)",
        "darkpurple-90": "rgba(35, 16, 68, 0.9)",
        grey: "rgba(35, 16, 68)",
        "grey-90": "rgba(35, 16, 68, 0.9)",
        "grey-70": "rgba(35, 16, 68, 0.7)",
        "grey-60": "rgba(35, 16, 68, 0.6)",
        "grey-50": "rgba(35, 16, 68, 0.5)",
        "grey-40": "rgba(35, 16, 68, 0.4)",
        "grey-35": "rgba(35, 16, 68, 0.35)",
        "grey-30": "rgba(35, 16, 68, 0.3)",
        "grey-20": "rgba(35, 16, 68, 0.2)",
        "grey-25": "rgba(35, 16, 68, 0.25)",
        "grey-15": "rgba(35, 16, 68, 0.15)",
        "grey-10": "rgba(35, 16, 68, 0.1)",
        "grey-08": "rgba(35, 16, 68, 0.08)",
        "grey-06": "rgba(35, 16, 68, 0.06)",
        "grey-05": "rgba(35, 16, 68, 0.05)",
        "grey-04": "rgba(35, 16, 68, 0.04)",
        "grey-01": "rgba(35, 16, 68, 0.01)",
        "grey-00": "rgba(35, 16, 68, 0.00)",
        lightgrey: "rgba(243, 241, 245)",
        "lightgrey-20": "rgba(243, 241, 245, 0.2)",
        "lightgrey-30": "rgba(243, 241, 245, 0.3)",
        "lightgrey-85": "rgba(243, 241, 245, 0.85)",
        "lightgrey-90": "rgba(243, 241, 245, 0.9)",
        "dark-grey": "rgba(15, 5, 24)",
        "dark-grey-100": "rgba(15, 5, 24, 1)",
        "dark-grey-70": "rgba(15, 5, 24, 0.7)",
        "dark-grey-50": "rgba(15, 5, 24, 0.5)",
        "dark-grey-40": "rgba(15, 5, 24, 0.4)",
        "dark-grey-35": "rgba(15, 5, 24, 0.35)",
        "dark-grey-30": "rgba(15, 5, 24, 0.3)",
        "dark-grey-20": "rgba(15, 5, 24, 0.2)",
        "dark-grey-10": "rgba(15, 5, 24, 0.1)",
        "dark-grey-15": "rgba(15, 5, 24, 0.15)",
        "dark-grey-07": "rgba(15, 5, 24, 0.07)",
        "dark-grey-05": "rgba(15, 5, 24, 0.05)",
        "dark-grey-02": "rgba(15, 5, 24, 0.02)",
        "blue-grey-100": "rgba(239, 241, 246, 1)",
        "blue-grey-60": "rgba(239, 241, 246, 0.6)",
        "blue-grey-50": "rgba(239, 241, 246, 0.5)",
        "blue-grey-30": "rgba(247, 248, 251, 0.3)",
        "blue-grey-25": "rgba(247, 248, 251, 0.25",
        "blue-grey-15": "rgba(251, 251, 252, 0.15)",
        "blue-grey-10": "rgba(251, 251, 252, 0.1)",
        "blue-grey-100p": "rgba(251, 251, 252, 1)",
        "blue-grey-10rgb": "rgb(251, 251, 252)",
        "card-stroke": "rgba(15, 5, 24, 0.20)",
        "card-stroke-50": "rgba(15, 5, 24, 0.50)",
        "card-stroke-30": "rgba(15, 5, 24, 0.30)",
        "card-stroke-15": "rgba(15, 5, 24, 0.15)",
        "light-purple-100": "rgba(96, 93, 233, 1)",
        "light-purple-50": "rgba(96, 93, 233, 0.50)",
        "light-purple-10": "rgba(96, 93, 233, 0.10)",
        "light-purple150-100": "rgba(55, 52, 165, 1)",
        "light-purple150-50": "rgba(55, 52, 165, 0.5)",
        red: "rgba(239, 71, 111, 1)",
        "red-70": "rgba(239, 71, 111, 0.7)",
        "red-50": "rgba(239, 71, 111, 0.5)",
        "red-40": "rgba(239, 71, 111, 0.4)",
        "red-30": "rgba(239, 71, 111, 0.3)",
        "red-20": "rgba(239, 71, 111, 0.2)",
        "red-10": "rgba(239, 71, 111, 0.1)",
        whitelight: "rgba(255, 255, 255, 0.25)",
        whiteOpacity: "rgba(255, 255, 255, 0.65)",
        green: "rgba(2, 195, 154, 1)",
        yellow: "rgba(245, 187, 0, 1)",
        black: "rgba(0, 0, 0, 1)",
        white: "rgba(255, 255, 255, 1)",
        offwhite: "rgba(252,252,252,1)",
        orange: "rgba(243, 141, 44, 1)",
        blue: "rgba(73, 70, 221, 1)",
        "soft-green": "rgba(0, 178, 104, 1)",
        "light-green-10": "rgba(2, 195, 114, 0.10)",
        "light-green-30": "rgba(2, 195, 114, 0.30)",
        "light-green-50": "rgba(2, 195, 114, 0.50)",
        "light-green": "rgba(2, 195, 114, 1)",
        "soft-orange": "rgba(242, 115, 23, 1)",
        "light-orange-30": "rgba(250, 158, 51, 0.30)",
        "light-orange-50": "rgba(250, 158, 51, 0.50)",
        "light-orange": "rgba(250, 158, 51, 1)",
        "old-primary": "#231044",
        "old-secondary": "#4946DD",
        "old-lightblue": "#7187FF",
        "old-red": "#EF476F",
        "old-green": "#02C39A",
        "old-yellow": "#F5BB00",
        "old-lightgrey": "#F0EEF2",
        "old-grey-10": "#E9E7EC",
        "old-grey-30": "#BDB7C7",
        "old-grey-60": "#7B708F",
        foreground: "hsl(var(--foreground))",
        card: {
          DEFAULT: "hsl(var(--card))",
          foreground: "hsl(var(--card-foreground))",
        },
        popover: {
          DEFAULT: "hsl(var(--popover))",
          foreground: "hsl(var(--popover-foreground))",
        },
        muted: {
          DEFAULT: "hsl(var(--muted))",
          foreground: "hsl(var(--muted-foreground))",
        },
        accent: {
          DEFAULT: "hsl(var(--accent))",
          foreground: "hsl(var(--accent-foreground))",
        },
        destructive: {
          DEFAULT: "hsl(var(--destructive))",
          foreground: "hsl(var(--destructive-foreground))",
        },
        border: "hsl(var(--border))",
        input: "hsl(var(--input))",
        ring: "hsl(var(--ring))",
        chart: {
          "1": "hsl(var(--chart-1))",
          "2": "hsl(var(--chart-2))",
          "3": "hsl(var(--chart-3))",
          "4": "hsl(var(--chart-4))",
          "5": "hsl(var(--chart-5))",
        },

        // Semantic
        "primary-background-color": "hsl(var(--primary-background-color))",
        "primary-background-hover-color": "hsl(var(--primary-background-hover-color))",
        "primary-border-hover-color": "hsl(var(--primary-border-hover-color))",
        "primary-background-focus-color": "var(--primary-background-focus-color)",
        "primary-border-focus-color": "var(--primary-border-focus-color)",
      },
      lineHeight: {
        none: "1",
        tight: "1.2",
        normal: "1.4",
        _normal: "normal",
        "5.5": "22px",
        p140: "140%",
        p100: "100%",
      },
      fontSize: {
        "16": "16px",
        xxl: "28px",
        xll: "20px",
        xl: "22px",
        lg: "17px",
        md: "15px",
        mdm: "14px",
        sm: "13px",
        xs: "12px",
        xxs: "10px",
        "old-2xl": "var(--h1-size)",
        "old-xl": "var(--h2-size)",
        "old-lg": "var(--h3-size)",
        "old-base": "var(--text-size)",
        "old-md": "var(--text-size)",
        "old-sm": "var(--smalltext-size)",
        small: "var(--smalltext-size)",
        "old-small": "var(--smalltext-size)",
        "old-xs": "var(--tinytext-size)",
        "bold-oxtext": "var(--boxtext-size)",
        "nold-ote": "var(--tinytext-size)",
        "new-xs": ["0.75rem", { lineHeight: "1rem" }],
        "new-sm": ["0.875rem", { lineHeight: "1.25rem" }],
        "new-base": ["1rem", { lineHeight: "1.5rem" }],
        "new-lg": ["1.125rem", { lineHeight: "1.75rem" }],
        "new-xl": ["1.25rem", { lineHeight: "1.75rem" }],
        "new-1.5xl": ["1.375rem", { lineHeight: "140%" }],
        "new-2xl": ["1.5rem", { lineHeight: "2rem" }],
        "new-3xl": ["1.875rem", { lineHeight: "2.25rem" }],
        "new-4xl": ["2.125rem", { lineHeight: "3rem" }],
        "new-5xl": ["3rem", { lineHeight: "1" }],
        "new-5.5xl": ["3rem", { lineHeight: "4.188rem" }],
        "new-6xl": ["3.75rem", { lineHeight: "1" }],
        "new-7xl": ["4.5rem", { lineHeight: "1" }],
        "new-8xl": ["6rem", { lineHeight: "1" }],
        "new-9xl": ["8rem", { lineHeight: "1" }],
        code: ["0.75rem", { lineHeight: "180%" }],
      },
      fontWeight: {
        light: "400",
        normal: "500",
        medium: "600",
        semibold: "700",
        bold: "800",
        vbold: "900",
      },
      minWidth: {
        "0": "0",
        "1/4": "25%",
        "1/2": "50%",
        "3/4": "75%",
        full: "100%",
      },
      maxWidth: {
        page: "1440px",
        "page-section": "1072px",
        xs: "320px",
        xxs: "160px",
      },
      width: dotSizes,
      height: { ...dotSizes, heroBackgroundLaptop: "464px", heroBackground: "331px", heroBackgroundSmall: "422px" },
      padding: {
        "dot-size": "var(--dot-size)",
        "dot-size2": "calc(var(--dot-size) *2)",
        "dot-1": "calc(var(--spacing) * 1 + var(--dot-size))",
        "dot-2": "calc(var(--spacing) * 2 + var(--dot-size))",
        "dot-3": "calc(var(--spacing) * 3 + var(--dot-size))",
        "dot-4": "calc(var(--spacing) * 4 + var(--dot-size))",
        "dot-5": "calc(var(--spacing) * 5 + var(--dot-size))",
        "dot-6": "calc(var(--spacing) * 6 + var(--dot-size))",
      },
      opacity: {
        "40": "0.4",
        "65": ".65",
      },
      spacing: {
        "7": "1.75rem",
        "14": "3.5rem",
        "18": "4.5rem",
        "30": "7.5rem",
        header: "3.5rem",
        "dot-size": "var(--dot-size)",
        "dot-size2": "calc(var(--dot-size) *2)",
        "dot-1": "calc(var(--spacing) * 1 - var(--dot-size))",
        "dot-2": "calc(var(--spacing) * 2 - var(--dot-size))",
        "dot-3": "calc(var(--spacing) * 3 - var(--dot-size))",
        "dot-4": "calc(var(--spacing) * 4 - var(--dot-size))",
        "dot-5": "calc(var(--spacing) * 5 - var(--dot-size))",
        "dot-6": "calc(var(--spacing) * 6 - var(--dot-size))",
        "spacer-1": "var(--spacer-height)",
        "spacer-1/2": "calc(var(--spacer-height) / 2)",
        "spacer-2/3": "calc(var(--spacer-height) * .75)",
        "spacer-3/2": "calc(var(--spacer-height) * 1.5)",
        "spacer-2": "calc(var(--spacer-height) * 2)",
        page: "var(--page-spacing)",
        "page-1/2": "var(--page-spacing-half)",
        "7.5": "1.875rem",
      },
      strokeWidth: {
        "1": "1",
        "2": "2",
        "3": "3",
        "1.5": "1.5",
      },
      boxShadow: (theme: any) => ({
        "purple-small": `0px 1.5px 7.5px ${theme("colors.purple-20")}`,
        "darkpurple-small": `0px 1.5px 7.5px ${theme("colors.grey-20")}`,
        card: "0px 6px 26px rgba(35, 16, 68, 0.06), 0px 1.81px 10.63px rgba(35, 16, 68, 0.04), 0px 0.75px 6.75px rgba(35, 16, 68, 0.04), 0px 0.27px 5px rgba(35, 16, 68, 0.027)",
        logincard: `0px 0.40371015667915344px 7.428571701049805px 0px ${theme("colors.grey-00")}, 0px 1.1162068843841553px 10.028571128845215px 0px ${theme("colors.grey-01")}, 0px 2.687396287918091px 15.793142318725586px 0px ${theme("colors.grey-05")}, 0px 8.914284706115723px 38.628570556640625px 0px ${theme("colors.grey-05")}`,
        "package-tab-card":
          "0px 0.40371015667915344px 7.428571701049805px 0px rgba(35, 16, 68, 0.00), 0px 1.1162068843841553px 10.028571128845215px 0px rgba(35, 16, 68, 0.01), 0px 2.687396287918091px 15.793142318725586px 0px rgba(35, 16, 68, 0.05), 0px 8.914284706115723px 38.628570556640625px 0px rgba(35, 16, 68, 0.05)",
        // old wasmer
        darkpurple: "0px 3px 14px rgba(35, 16, 68, 0.2)",
        lightpurple: "0px 3px 14px rgba(73, 70, 221, 0.3)",
      }),
      textShadow: {
        sm: "0 1px 2px var(--tw-shadow-color)",
        DEFAULT: "0px 0px 4px var(--tw-shadow-color)",
        lg: "0 8px 16px var(--tw-shadow-color)",
      },
      dropShadow: {
        subheader: "0px 0px 4px 0px rgba(255, 255, 255, 0.25)",
      },

      accentColor: {
        mischka: "#D8D7DA",
      },
      borderColor: {
        mischka: "#D8D7DA",
      },
      borderRadius: {
        none: "0",
        sm: "calc(var(--radius) - 4px)",
        DEFAULT: "0.25rem",
        full: "9999px",
        lg: "var(--radius)",
        md: "calc(var(--radius) - 2px)",
      },
      zIndex: {
        "-1": "-1",
        heroBackground: "-1",
        modal: "20000",
        dropdown: "10000",
        backdrop: "9000",
        badge: "8000",
        tooltip: "20000",
      },
      transitionTimingFunction: {
        fast: "cubic-bezier(0.25, 0, 0, 1)",
      },
      fill: {
        none: "none",
      },
      backgroundImage: {
        "github-to-edge": "url('/github-to-edge.png')",
        "banner-gradient": "url('/banner-gradient.png')",
        "deploy-apps-list": "url('/apps-list-deploy.png')",
        "gradient-radial": "radial-gradient(var(--tw-gradient-stops))",
        "gradient-conic": "conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))",
        "gradient-25": "linear-gradient(180deg, #CDD5E9 0%, #FCFCFE 100%)",
        "gradient-15": " linear-gradient(180deg, #EFF1F6 0%, #FCFCFE 100%)",
        "regular-button-hover": "linear-gradient(0deg, rgba(251, 251, 252, 0.05) 0%, rgba(251, 251, 252, 0.05) 100%)",
        "regular-button-pressed": "linear-gradient(0deg, rgba(251, 251, 252, 0.15) 0%, rgba(251, 251, 252, 0.15) 100%)",
        "gradient-success": "linear-gradient(180deg, #D9F6EA 0%, #F7FDFB 150%)",
        "gradient-error": "linear-gradient(180deg, #FDE3E9 0%, #FFFBFC 150%)",
        "gradient-warning": "linear-gradient(180deg, #FEF0E0 0%, #FFFDFB 150%)",
        "linear-settings": "linear-gradient(180deg, rgb(252 252 254) 0%, rgb(239 241 246) 100%)",
        overlapBg: "linear-gradient(180deg, #4AB3FF 0%, rgba(74, 179, 255, 0.00) 100%)",
        verified: "linear-gradient(0deg, rgba(96, 93, 233, 0.50) 0%, rgba(96, 93, 233, 0.50) 100%)",
        verifying: "linear-gradient(0deg, rgba(250, 158, 51, 0.15) 0%, rgba(250, 158, 51, 0.15) 100%)",
        "gradient-code": "linear-gradient(0deg, rgba(251, 251, 252, 0.08) 0%, rgba(251, 251, 252, 0.08) 100%)",
        "gradient-Disabled": "linear-gradient(0deg, rgba(15, 5, 24, 0.15) 0%, rgba(15, 5, 24, 0.15) 100%)",
        "gradient-active": "linear-gradient(0deg, #605DE9 0%, #605DE9 100%)",
        "appcard-footer": "linear-gradient(0deg, rgba(15, 5, 24, 0.03) 0%, rgba(15, 5, 24, 0.03) 100%)",
        "gradient-deny": "linear-gradient(0deg, rgba(15, 5, 24, 0.07) 0%, rgba(15, 5, 24, 0.07) 100%)",
      },
      blur: {
        "new-10": "10px",
        "new-50": "50px",
        "new-60": "60px",
        "new-70": "70px",
      },
      letterSpacing: {
        wide: ".020em",
      },
      screens: {
        mobile: "375px",
        tablet: "768px",
        desktop: "1440px",
        xxsm: "374px",
        xsm: "480px",
        xmd: "900px",
        xlg: "1100px",
        xxlg: "1152px",
        laptop: "1152px",
        xlscreen: "1201px",
        wscreen: "1440px",
      },
      animation: {
        wiggle: "wiggle 1s ease-in-out infinite",
        "bounce-once": "bounce 1s 1",
        "spin-anti-clockwise": "spinanti 1s linear infinite",
        "pulse-fast": " pulse 0.5s cubic-bezier(0.4, 0, 0.6, 1) infinite",
        heartBeat: "heartBeat 1.5s infinite",
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
      },
      keyframes: {
        heartBeat: {
          "0%, 100%": {
            transform: "scale(1)",
          },
          "50%": {
            transform: "scale(1.4)",
          },
        },
        spinanti: {
          from: {
            transform: "rotate(360deg)",
          },
          to: {
            transform: "rotate(0deg)",
          },
        },
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
      },
    },
  },
  plugins: [
    require("@tailwindcss/typography"),
    require("tailwind-scrollbar"),
    require("@headlessui/tailwindcss")({ prefix: "ui" }),
    plugin(function ({ matchUtilities, theme }: any) {
      matchUtilities(
        {
          "text-shadow": (value: any) => ({
            textShadow: value,
          }),
        },
        { values: theme("textShadow") }
      );
    }),
    // require("tailwindcss-animate"),
  ],
};

export default config;
