import React from "react";

import NewLoading from "@components/NewLoading";

import css from "./LoadingIcon.module.css";
import clsx from "clsx";

const LoadingIcon = (props: { className?: string }) => {
  return <NewLoading className={clsx(css.loadingIcon, props.className)} />;
};
export default LoadingIcon;
