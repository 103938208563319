import React from "react";

const ReturnIconDisabled = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#0F0518" fillOpacity="0.1" />
      <path
        d="M7 13.5C8.09282 13.5 16.7083 13.5 16.7083 13.5C16.7083 12.5774 16.7083 8.5 16.7083 8.5H14.5625"
        stroke="#0F0518"
        strokeOpacity="0.3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 16.5L6 13.5L9 10.5"
        stroke="#0F0518"
        strokeOpacity="0.3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default ReturnIconDisabled;
