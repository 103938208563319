import clsx from "clsx";
import React, { ReactElement, ReactNode } from "react";

import CheckMarkIcon from "@assets/icons/CheckMarkIcon";
import ExclamationIcon from "@assets/icons/ExclamationIcon";

import css from "./Adorment.module.css";

const icons: { [index: string]: ReactElement } = {
  verified: <CheckMarkIcon className={css.iconVerified} />,
  verifying: <ExclamationIcon className={css.iconVerifying} />,
};

export type AdormentVariants = "verified" | "verifying" | "none";
interface AdormentInterface {
  text?: ReactNode;
  icon?: ReactNode;
  variant?: AdormentVariants;
  className?: string;
}
const Adorment = ({ variant = "verified", text, className }: AdormentInterface) => {
  return (
    <div className={clsx(css.wrapper, css[variant], className)}>
      {icons[variant]}
      <span className={css.text}>{text}</span>
    </div>
  );
};
export default Adorment;
