/**
 * @generated SignedSource<<306dc959739b78e94502dc6de431384e>>
 * @relayHash 9c02794d6e94d4b7f2b6098dedb15b1d
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9c02794d6e94d4b7f2b6098dedb15b1d

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChangeUserPasswordInput = {
  clientMutationId?: string | null | undefined;
  oldPassword?: string | null | undefined;
  password: string;
  token?: string | null | undefined;
};
export type Account_UpdatePassword_SettingsAccountPageMutation$variables = {
  input: ChangeUserPasswordInput;
};
export type Account_UpdatePassword_SettingsAccountPageMutation$data = {
  readonly changeUserPassword: {
    readonly token: string | null | undefined;
  } | null | undefined;
};
export type Account_UpdatePassword_SettingsAccountPageMutation = {
  response: Account_UpdatePassword_SettingsAccountPageMutation$data;
  variables: Account_UpdatePassword_SettingsAccountPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangeUserPasswordPayload",
    "kind": "LinkedField",
    "name": "changeUserPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Account_UpdatePassword_SettingsAccountPageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Account_UpdatePassword_SettingsAccountPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "9c02794d6e94d4b7f2b6098dedb15b1d",
    "metadata": {},
    "name": "Account_UpdatePassword_SettingsAccountPageMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "91e827a6c2b1638030d8cfecee9cb9c1";

export default node;
