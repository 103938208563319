import clsx from "clsx";
import React from "react";

import RedoIcon from "@assets/icons/RedoIcon";
import cssSpinner from "@components/shared/LoadingIcon/LoadingIcon.module.css";

import CancelButton from "../CancelButton";
import cssResendBtn from "./ResendBtn.module.css";

interface ResendButtonInterface {
  onClick?: () => void;
  spinning?: boolean;
}
const ResendButton = ({ spinning = false, onClick = () => {} }: ResendButtonInterface) => {
  return (
    <CancelButton
      theme={cssResendBtn}
      text="Resend"
      iconStart={
        <RedoIcon
          className={clsx({
            [cssSpinner.spiningAntiClockWise]: spinning,
          })}
        />
      }
      onClick={onClick}
    />
  );
};
export default ResendButton;
