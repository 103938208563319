import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

interface DividerProps {
  className?: string;
}
const Divider: React.FC<DividerProps> = ({ className }) => {
  return <hr className={twMerge(clsx("h-[1px] w-full rounded bg-black", className))} />;
};

export default Divider;
