/**
 * @generated SignedSource<<268ac1a21cee8519fcc1c0be83647054>>
 * @relayHash 6aa91823d5fee8d182147d4b9334b69b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6aa91823d5fee8d182147d4b9334b69b

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateUserInfoInput = {
  avatar?: string | null | undefined;
  bio?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  fullName?: string | null | undefined;
  github?: string | null | undefined;
  location?: string | null | undefined;
  twitter?: string | null | undefined;
  userId?: string | null | undefined;
  websiteUrl?: string | null | undefined;
};
export type Account_SettingsAccountPageMutation$variables = {
  input: UpdateUserInfoInput;
};
export type Account_SettingsAccountPageMutation$data = {
  readonly updateUserInfo: {
    readonly clientMutationId: string | null | undefined;
    readonly user: {
      readonly avatar: string;
      readonly bio: string | null | undefined;
      readonly email: string;
      readonly fullName: string;
      readonly githubUrl: string | null | undefined;
      readonly id: string;
      readonly isEmailValidated: boolean;
      readonly location: string | null | undefined;
      readonly twitterUrl: string | null | undefined;
      readonly username: string;
      readonly websiteUrl: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type Account_SettingsAccountPageMutation = {
  response: Account_SettingsAccountPageMutation$data;
  variables: Account_SettingsAccountPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserInfoPayload",
    "kind": "LinkedField",
    "name": "updateUserInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bio",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatar",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isEmailValidated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "githubUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "twitterUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "websiteUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "location",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Account_SettingsAccountPageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Account_SettingsAccountPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "6aa91823d5fee8d182147d4b9334b69b",
    "metadata": {},
    "name": "Account_SettingsAccountPageMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "9a9ce4cb839f530295495e75ce28a99e";

export default node;
