import React, { ReactNode } from "react";
import css from "./Backdrop.module.css";
import clsx from "clsx";

interface BackdropInterface {
  children?: ReactNode;
  onClick?: () => void;
}
const Backdrop = ({ children, onClick }: BackdropInterface) => {
  return (
    <div className={clsx(css.backdrop)} onClick={onClick}>
      {children}
    </div>
  );
};
export default Backdrop;
