import React from "react";
import css from "./PackageRow.module.css";
import clsx from "clsx";
import Image from "next/image";

interface PackageRowInterface {
  theme?: { readonly [key: string]: string };
  iconUrl?: string;
  displayName?: string;
  namespace?: string;
  pkgName?: string;
}
const PackageRow = ({ theme = {}, displayName, iconUrl, namespace, pkgName }: PackageRowInterface) => {
  return (
    <div className={clsx(theme.wrapper, css.wrapper)}>
      <div className={clsx(theme.content, css.content)}>
        {iconUrl && (
          <Image
            className={clsx(theme.icon, css.icon)}
            src={iconUrl}
            alt={displayName || `${namespace}/${pkgName}`}
            width={40}
            height={40}
          />
        )}
        {displayName && <div className={clsx(theme.displayName, css.displayName)}>{displayName}</div>}
        {!displayName && (
          <span className={clsx(theme.fullPkgname, css.fullPkgname)}>
            {namespace && <div className={clsx(theme.namespace, css.namespace)}>{namespace}</div>}
            {pkgName && <div className={clsx(theme.pkgName, css.pkgName)}>{pkgName}</div>}
          </span>
        )}
      </div>
    </div>
  );
};
export default PackageRow;
