import React from "react";

interface RedoIconInterface {
  svgFill?: string;
  fillColor?: string;
  strokeColor?: string;
  width?: number;
  height?: number;
  className?: string;
}
const RedoIcon = ({
  width = 22,
  height = 22,
  strokeColor = "#605DE9",
  fillColor = "#605DE9",
  svgFill = "none",
  className = "",
}: RedoIconInterface) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill={svgFill}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3285_139131)">
        <path
          d="M4.75 12C4.75 11.5858 4.41421 11.25 4 11.25C3.58579 11.25 3.25 11.5858 3.25 12H4.75ZM11 5L10.5641 4.3897C10.3134 4.56879 10.1977 4.88299 10.2724 5.1819L11 5ZM14.9359 3.1103C15.273 2.86954 15.3511 2.40113 15.1103 2.06407C14.8695 1.72701 14.4011 1.64894 14.0641 1.8897L14.9359 3.1103ZM11.2724 9.1819C11.3729 9.58375 11.7801 9.82807 12.1819 9.72761C12.5837 9.62715 12.8281 9.21994 12.7276 8.8181L11.2724 9.1819ZM17.25 12C17.25 15.4518 14.4518 18.25 11 18.25V19.75C15.2802 19.75 18.75 16.2802 18.75 12H17.25ZM11 18.25C7.54822 18.25 4.75 15.4518 4.75 12H3.25C3.25 16.2802 6.71979 19.75 11 19.75V18.25ZM11 5.75C14.4518 5.75 17.25 8.54822 17.25 12H18.75C18.75 7.71979 15.2802 4.25 11 4.25V5.75ZM11.4359 5.6103L14.9359 3.1103L14.0641 1.8897L10.5641 4.3897L11.4359 5.6103ZM10.2724 5.1819L11.2724 9.1819L12.7276 8.8181L11.7276 4.8181L10.2724 5.1819Z"
          fill={fillColor}
        />
      </g>
      <path
        d="M11 5C14.866 5 18 8.13401 18 12C18 15.866 14.866 19 11 19C7.13401 19 4 15.866 4 12C4 10.1722 4.70051 8.5081 5.8477 7.2614"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <defs>
        <clipPath id="clip0_3285_139131">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default RedoIcon;
