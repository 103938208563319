import React from "react";
import resolveConfig from "tailwindcss/resolveConfig";

import ChevronLeftIcon from "@assets/icons/ChevronLeftIcon";
import tailwindConfig from "@root/tailwind.config";

import RegularButton, { ButtonStateType } from "../RegularButton";
import css from "./ConfirmButton.module.css";

const fullConfig = resolveConfig(tailwindConfig);
const primColor = (fullConfig.theme?.colors as any)["blue-grey-100p"];

interface ConfirmButtonInterface {
  text: string;
  link?: string;
  showIcon?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  state?: ButtonStateType;
}
const ConfirmButton: React.FC<ConfirmButtonInterface> = ({
  text,
  link,
  showIcon = true,
  state,
  disabled,
  onClick,
  ...otherProps
}) => {
  return (
    <RegularButton
      {...otherProps}
      theme={css}
      variant="primary"
      text={text}
      iconEnd={showIcon && <ChevronLeftIcon fillColor={primColor} />}
      link={link}
      disabled={disabled}
      onClick={onClick}
      state={state}
    />
  );
};
export default ConfirmButton;
