import React from "react";

interface CameraPlusIconInterface {
  svgFill?: string;
  strokeColor?: string;
  width?: number;
  height?: number;
  className?: string;
}
const CameraPlusIcon = ({
  width = 34,
  height = 34,
  svgFill = "none",
  strokeColor = "#FBFBFC",
  className = "",
}: CameraPlusIconInterface) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 34"
      fill={svgFill}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.55461 16.4554L10.3249 13.8614C10.4767 13.6637 10.6693 13.5011 10.8896 13.3846C11.1099 13.2681 11.3527 13.2005 11.6015 13.1863H15.3152C15.564 13.2005 15.8069 13.2681 16.0272 13.3846C16.2475 13.5011 16.4401 13.6637 16.5919 13.8614L17.8995 15.7804C18.0516 15.978 18.2445 16.1407 18.4651 16.2571C18.6857 16.3736 18.9287 16.4413 19.1778 16.4554H21.6313C22.3093 16.5252 22.9368 16.8459 23.3905 17.3546C23.8441 17.8633 24.0912 18.5232 24.0832 19.2048V28.7148C24.0832 29.3651 23.8248 29.9887 23.365 30.4486C22.9052 30.9084 22.2816 31.1667 21.6313 31.1667H5.28544C4.63516 31.1667 4.01152 30.9084 3.5517 30.4486C3.09189 29.9887 2.83357 29.3651 2.83357 28.7148V19.2048C2.8255 18.5232 3.07257 17.8633 3.52624 17.3546C3.97991 16.8459 4.60739 16.5252 5.28544 16.4554H8.55461Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4583 27.8975C15.7152 27.8975 17.5447 26.0679 17.5447 23.811C17.5447 21.5541 15.7152 19.7245 13.4583 19.7245C11.2014 19.7245 9.37183 21.5541 9.37183 23.811C9.37183 26.0679 11.2014 27.8975 13.4583 27.8975Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M26.2083 4.25L26.2083 11.3333" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M29.75 7.79169L22.6667 7.79169" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
export default CameraPlusIcon;
