"use client"
import clsx from "clsx";
import   {
  ChangeEventHandler,
  HTMLInputTypeAttribute,
  KeyboardEventHandler,
  TextareaHTMLAttributes,
  useEffect,
  useState,
} from "react";

import ReturnIconDefault from "@assets/icons/ReturnIconDefault";
import ReturnIconDisabled from "@assets/icons/ReturnIconDisabled";
import ReturnIconInactive from "@assets/icons/ReturnIconInactive";
import NewLoading from "@components/NewLoading";
import IconButton from "@components/shared/Buttons/IconButton";

import css from "./DefaultInputBox.module.css";

export interface DefaultInputBoxInterface {
  initValue?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onEnterKeyDown?: Function;
  pattern?: string;
  type?: HTMLInputTypeAttribute;
  isError?: boolean;
  errorMsg?: string | string[];
  inactive?: boolean;
  disabled?: boolean;
  showIcon?: boolean;
  theme?: { readonly [key: string]: string };
  multi?: boolean;
  rows?: number;
  fullWidth?: boolean;
  value?: string;
  submitting?: boolean;
}

const DefaultInputBox = ({
  theme = {},
  initValue,
  value,
  label,
  errorMsg,
  placeholder,
  className,
  fullWidth,
  onChange,
  onEnterKeyDown,
  pattern,
  type,
  isError,
  inactive,
  disabled,
  showIcon = true,
  multi = false,
  rows = 1,
  submitting,
  ...attrs
}: DefaultInputBoxInterface &
  React.AllHTMLAttributes<HTMLInputElement> &
  TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  const [locValue, setLocValue] = useState(initValue || "");
  const [isInactive, setIsInactive] = useState((initValue || "") == "" || inactive);

  const _onChange: ChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocValue(e.target.value);
    onChange && onChange(e);
  };

  const _onKeyDown: KeyboardEventHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "Enter" && onEnterKeyDown) {
      onEnterKeyDown();
    }
  };

  useEffect(() => {
    setIsInactive(value == "");

    setLocValue(value || initValue || "");
  }, [value]);

  useEffect(() => {
    setIsInactive(inactive);
  }, [inactive]);

  return (
    <div
      className={clsx([css.wrapper, theme.wrapper], className, {
        [css.fullWidth]: fullWidth,
        [css.inactive]: inactive,
        [css.error]: isError,
      })}
    >
      {!multi && (
        <input
          type={type || ""}
          className={clsx([css.inputBox, theme.inputBox])}
          placeholder={placeholder}
          pattern={pattern || undefined}
          onChange={_onChange}
          onKeyDown={_onKeyDown}
          value={value || locValue}
          disabled={disabled}
          data-cy="inputbox"
          {...attrs}
        />
      )}
      {multi && (
        <textarea
          rows={rows}
          className={clsx([css.inputBox, theme.inputBox])}
          placeholder={placeholder}
          value={value || locValue}
          onChange={_onChange}
          onKeyDown={_onKeyDown}
          disabled={disabled}
          data-cy="textareabox"
          {...attrs}
        />
      )}

      <label className={(value || locValue)?.trim() != "" ? css.labelContainer : "hidden"}>
        <span className={css.label}>{label}</span>
        <span>&nbsp;</span>
      </label>
      {!showIcon ? null : submitting ? (
        <NewLoading className={css.submittingIcon} />
      ) : (
        <IconButton
          className={css.actionButton}
          inactive={isInactive || (value || locValue)?.trim() == ""}
          disabled={disabled || isError}
          icon={<ReturnIconDefault />}
          iconInactive={<ReturnIconInactive />}
          iconDisabled={<ReturnIconDisabled />}
          onClick={() => onEnterKeyDown && onEnterKeyDown()}
        />
      )}
      {isError && (
        <div className={css.errorMsg || ""}>
          {typeof errorMsg == "string" && errorMsg}
          {Array.isArray(errorMsg) && errorMsg.map((err: string, idx: number) => <p key={idx}>{err}</p>)}
        </div>
      )}
    </div>
  );
};
export default DefaultInputBox;
