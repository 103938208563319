import clsx from "clsx";
import Image from "next/image";
import React, { ReactNode, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import CameraPlusIcon from "@assets/icons/CameraPlusIcon";

import css from "./ProfilePicture.module.css";
import { CardHeader } from "..";

interface ProfilePictureInterface {
  title?: string;
  subTitle?: string;
  avatar?: string;
  onAvatarChange?: (newAvatar: File) => void;
  uploading?: boolean;
  body?: ReactNode;
  direction?: "ltr" | "rtl";
  placeholder?: string;
  className?: string;
}
const ProfilePicture = ({
  uploading = false,
  placeholder = "Drop your image here",
  body,
  title,
  subTitle,
  avatar,
  direction = "ltr",
  onAvatarChange = () => {},
  className = "",
}: ProfilePictureInterface) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onAvatarChange(acceptedFiles[0]);
    },
    [onAvatarChange]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: ["image/png", "image/jpeg"],
  });

  return (
    <div className={clsx(css.picWrapper, className)}>
      <div className={css.content}>
        <div className={css.headerWrap}>
          {(body || title || subTitle) && (
            <div className={css.headerBody}>
              {(title || subTitle) && <CardHeader title={title!} subTitle={subTitle} />}
              {body}
            </div>
          )}
          <div
            className={clsx(css.logoZone, {
              [css.logoZoneRTL]: direction == "rtl",
              [css.avatarZoneDragOn]: isDragActive,
            })}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <div className={css.label}>{placeholder}</div>
            <div className={clsx(css.avatar, { [css.uploading]: uploading })}>
              <div className={css.iconWrapper}>
                <Image src={avatar || "/images/team/unknown.png"} alt={""} width={64} height={64} />
                <div className={css.backdrop}>
                  <CameraPlusIcon className={css.addImgIcon} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePicture;
