import React from "react";

interface CheckMarkIconInterface {
  svgFill?: string;
  strokeColor?: string;
  width?: number;
  height?: number;
  className?: string;
}
const CheckMarkIcon = ({
  width = 22,
  height = 23,
  strokeColor = "#605DE9",
  svgFill = "none",
  className = "",
}: CheckMarkIconInterface) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 23"
      fill={svgFill}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9032 8.30078C14.653 9.96811 9.57409 15.9491 9.57409 15.9491C9.57409 15.9491 6.64147 13.6873 5.75391 12.7998"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default CheckMarkIcon;
