import clsx from "clsx";
import React, { ReactElement, ReactNode } from "react";

import CloseIcon from "@assets/icons/CloseIcon";

import BackButton from "../Buttons/BackButton";
import { InlineNotificationInterface } from "../ToasterAlert";
import Backdrop from "./Backdrop";
import css from "./Modal.module.css";

interface ModalInterface {
  open?: boolean;
  announcement?: ReactElement<InlineNotificationInterface>;
  footerAnnouncement?: ReactElement<InlineNotificationInterface>;
  children?: ReactNode;
  header?: ReactNode;
  footer?: ReactElement;
  subHeader?: ReactNode;
  body?: ReactNode;
  className?: string;
  outerClick?: () => void;
  onClose?: () => void;
  closeBtn?: boolean;
  onBackClick?: () => void;
}

const Modal = ({
  open,
  header,
  subHeader,
  announcement,
  footerAnnouncement,
  body,
  footer,
  outerClick,
  onClose,
  onBackClick,
  closeBtn = true,
  children,
  className,
}: ModalInterface) => {
  return (
    <>
      {open && (
        <Backdrop onClick={outerClick}>
          <div
            className={clsx(css.wrapper, { [css.paddingBottomNone]: footerAnnouncement }, className)}
            onClick={(ev) => {
              ev.stopPropagation();
            }}
          >
            {closeBtn && (
              <div className={css.closeIcon} onClick={onClose}>
                <CloseIcon height={22} width={22} />
              </div>
            )}
            {onBackClick && <BackButton onClick={onBackClick} />}
            <div className={css.container}>
              {!children && (
                <>
                  <div className={css.header}>{header}</div>
                  {subHeader && <div className={css.subHeader}>{subHeader}</div>}
                  {announcement && React.cloneElement(announcement, { inModals: true, className: css.anouncement })}
                  {body}
                </>
              )}
              {children && <>{children}</>}
            </div>
            {!children && (
              <div className={css.footerSection}>
                {footer &&
                  React.cloneElement(footer, {
                    announcement: footerAnnouncement,
                    className: clsx({ [css.footerAnouncement]: footerAnnouncement }),
                  })}
              </div>
            )}
          </div>
        </Backdrop>
      )}
    </>
  );
};
export default Modal;

interface ModalFooterInterface {
  children?: ReactNode;
  cancelBtn?: ReactElement;
  confirmBtn?: ReactElement;
  announcement?: ReactElement<InlineNotificationInterface>;
  className?: string;
  classNameCancelWrap?: string;
  classNameConfirmWrap?: string;
}

const ModalFooter = ({
  children,
  cancelBtn,
  confirmBtn,
  announcement,
  className,
  classNameCancelWrap,
  classNameConfirmWrap,
}: ModalFooterInterface) => {
  return (
    <div className={clsx(css.footerWrap, className)}>
      {children && <>{children}</>}
      {!children && (
        <div className={css.footerContent}>
          <div className={css.footerButtons}>
            <div className={clsx(css.cancelBtnWrap, classNameCancelWrap)}>{cancelBtn}</div>
            <div className={clsx(css.confirmBtnWrap, classNameConfirmWrap)}>{confirmBtn}</div>
          </div>
          {announcement && React.cloneElement(announcement, { inModals: true, className: css.footerAnouncement })}
        </div>
      )}
    </div>
  );
};

export { ModalFooter };
