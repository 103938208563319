import React from "react";

import CloseIcon from "@assets/icons/CloseIcon";

import RegularButton, { RegularButtonInterface } from "../RegularButton";
import css from "./CancelButton.module.css";

interface CancelButtonInterface extends Omit<RegularButtonInterface, "variant" | "iconEnd"> {}
const CancelButton = (props: CancelButtonInterface) => {
  return (
    <RegularButton theme={css} iconStart={<CloseIcon />} {...props} variant="secondary" className={css.cancelBtn} />
  );
};
export default CancelButton;
